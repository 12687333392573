import api from "./api";

const apiUrl = typeof PROCESSING_API_URL !== 'undefined' && PROCESSING_API_URL; // eslint-disable-line no-undef

export default {
  getRates(currency) {
    return api
      .get(
        `${apiUrl}/public/processing/v1/exchange-rates/crypto?target=${currency}`
      )
      .then(({ data }) => {
        let rates = {};
        for (let i = 0; data.length > i; i++) {
          const { base, quote, rate } = data[i];
          rates[base] = { [quote]: rate };
        }

        return rates;
      });
  },

  getExchangeRateFromTo({ from, to }) {
    return api
      .get("/en/exchange/" + from + "/" + to)
      .then((response) => response.data);
  },

  getFiatCurrencyList() {
    return api.get("/rates/fiat-currency-list/").then(({ data }) => data);
  },

  getFiatRates() {
    return api.get("/rates/fiat-exchange-rates/").then(({ data }) => data);
  },
};
